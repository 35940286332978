<template>
  <div class="">
    <Header
      imgUrl="header-nutricionclinicaadulta.png"
      :classes="{ texto: 'align-middle', img: 'w-100' }"
      :texto="$t('productos.header_nca')"
    />
    <div class="container my-4">
      <div class="row">
        <div class="col-md-4 col-xs-12 pe-0">
          <img
            src="@/assets/images/productos/Lata_SustentaVainilla_prod.webp"
            class="img-fluid pb-2"
          />
          <div class="d-flex flex-column">
            <Sabor color="#e0c3ac" :texto="$t('productos.saborVainilla')" />

            <span class="mt-2">
              {{ $t("productos.presentacionLata", { gr: 350 }) }}
            </span>
          </div>
        </div>
        <div class="col-md-8 col-xs-12 ps-0 pl-mobile">
          <div class="row">
            <p class="pt-3" v-html="$t('productos.sustentaVainilla.descripcion')">
              
            </p>
          </div>

          <CaracteristicasNutricionales
            :columnas="2"
            :caracteristicas="caracteristicas"
          />

          <div class="row mt-3">
            <div class="col-md-5 d-flex justify-content-around align-items-left">
              <div>{{ $t("productos.alergenos") }}</div>
              <div>
                <img
                  :src="getIcon('icono-leche.png')"
                  class="img-fluid alergenos"
                />
              </div>
              <div>
                <img
                  :src="getIcon('icono-soja.png')"
                  class="img-fluid alergenos"
                />
              </div>
              <!-- <div>
                <img
                  src="@/assets/images/icons/sintacc.png"
                  class="img-fluid alergenos mb-4"
                />
              </div> -->
            </div>
            <!-- <div class="col d-flex align-items-end">
              <img
                src="@/assets/images/icons/hiperproteico21g.png"
                class="img-fluid hiperproteico"
              />
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("tablasNutricionales.titulo") }}</h5>
        <button
          class="btn btn-sm btn-azul"
          @click="toggleTablaNutricional = !toggleTablaNutricional"
        >
          {{ toggleTablaNutricional ? "+" : "-" }}
        </button>
      </div>
    </div>

    <div class="container" :class="{ oculto: toggleTablaNutricional }">
      <TableSustentaVainilla />
    </div>

    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.modoPreparacion.titulo") }}</h5>
        <!-- <button
          class="btn btn-sm btn-azul"
          @click="toggleModoPreparacion = !toggleModoPreparacion"
        > -->
          <!-- {{ toggleModoPreparacion ? "+" : "-" }} -->
        <!-- </button> -->
      </div>
    </div>

    <div class="container" :class="{ oculto: toggleModoPreparacion }">
      <ModoPreparacion
        :texto="$t('productos.modoPreparacion.texto')"
        :textoOrientativo="
          $t('productos.modoPreparacion.textoOrientativo', {porciones: '2 a 4', gr:350, ml: 1500})
        "
        :tabla="this.$i18n.locale == 'en' ? tablaEn : tablaEs"
        :medida="$t('productos.modoPreparacion.medida',{gr: '4,7', producto: 'Sustenta® Vainilla'})"
      />
    </div>

    <div class="container-fluid ntr-collapse bg-azul py-3 my-2">
      <div class="container">
        <h5>{{ $t("productos.otrosProductos") }}</h5>
      </div>
    </div>

    <CardsRecomendados :productos="['nutricion-clinica-adultos', 'modulos-nutricionales', 'espesante-resist']"/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sabor from "@/components/elementos/Sabor.vue";
import TableSustentaVainilla from "@/components/tablas/TableSustentaVainilla.vue";
import ModoPreparacion from "@/components/ModoPreparacion.vue";
import CaracteristicasNutricionales from "@/components/CaracteristicasNutricionales.vue";
import CardsRecomendados from "@/components/CardsRecomendados.vue";
import es from "@/locales/es.json";
import en from "@/locales/en.json";

export default {
  name: "SustentaVainilla",
  components: {
    Header,
    Sabor,
    TableSustentaVainilla,
    ModoPreparacion,
    CardsRecomendados,
    CaracteristicasNutricionales,
  },
  data() {
    return {
      toggleTablaNutricional: true,
      toggleModoPreparacion: !true,
      tests: es.productos.sustentaVainilla.caracteristicas,
      tablaEs: es.productos.sustentaVainilla.tablaPreparacion,
      tablaEn: en.productos.sustentaVainilla.tablaPreparacion,
      caracteristicas: {
        en: en.productos.sustentaVainilla.caracteristicas,
        es: es.productos.sustentaVainilla.caracteristicas, 
      },
    };
  },
  methods: {
    getIcon(fileName) {
      return require("../../assets/images/icons/" +
        this.$i18n.locale +
        "/" +
        fileName);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>